import { Box, Button } from "@mui/material";
import { InterfaceBookingData } from "../data/booking.interface";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getBookingReceivableContract } from "../data/booking.api";
import { RootState } from "../../../store";
import CustomTable from "../../../shared/CustomTable";
import moneyParser from "../../../shared/moneyParser";
import { Edit } from "@mui/icons-material";
import moment from "moment";

export default function BookingTableReceivableContract(props: {
    onEdit?: (data: InterfaceBookingData) => void,
    countReload?: number
}) {

    const dispatch = useDispatch<any>()

    const { bookingReceivableList } = useSelector((state: RootState) => state.booking)

    useEffect(() => {
        dispatch(getBookingReceivableContract())
    }, [dispatch, props.countReload])

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        key: "id",
                        name: "ID"
                    },
                    {
                        key: "ts_created",
                        name: "Tanggal Input",
                        render: (data) => moment(data.ts_created).format("YYYY-MM-DD")
                    },
                    {
                        key: "schedule",
                        name: "Keberangkatan",
                        render: (data) => data.schedule.start_city.name
                    },
                    {
                        key: "schedule",
                        name: "Tujuan",
                        render: (data) => data.schedule.finish_city.name
                    },
                    {
                        key: "total",
                        name: "Harga",
                        render: (data) => moneyParser(data.total)
                    },
                    {
                        key: "total_paid",
                        name: "Total Dibayarkan",
                        render: (data) => moneyParser(data.total_paid)
                    },
                    {
                        key: "total",
                        name: "Sisa Pembayaran",
                        render: (data) => moneyParser(data.total - data.total_paid),
                    }
                ]}
                dataList={bookingReceivableList}
                actionList={(props.onEdit)
                    ? (data) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                        </Box>
                    )
                    : undefined
                }
            />
        </Box>
    )
}