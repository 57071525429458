import { Container, Tab, Tabs, Typography } from "@mui/material";
import { InterfaceBookingData } from "../../features/booking/data/booking.interface";
import CustomModal from "../../shared/CustomModal";
import BookingEdit from "../../features/booking/components/BookingEdit";
import { useState } from "react";
import useAccess from "../../hooks/useAccess";
import DeliveryEdit from "../../features/delivery/components/DeliveryEdit";
import { InterfaceDeliveryData } from "../../features/delivery/data/delivery.interface";
import BookingTableReceivableContract from "../../features/booking/components/BookingTableReceivableContract";
import DeliveryTableReceivableContract from "../../features/delivery/components/DeliveryTableReceivableContract";

export default function ReceivableContractPage() {

    const access = useAccess()

    const [dataSelected, setDataSelected] = useState<InterfaceBookingData | InterfaceDeliveryData | undefined>(undefined)
    const [countReload, setCountReload] = useState(0)
    const [tabSelected, setTabSelected] = useState(0)

    const handleEdit = () => {
        setDataSelected(undefined)
        setCountReload(countReload + 1)
    }

    return (
        <Container>
            <CustomModal
                open={tabSelected === 0 && Boolean(dataSelected)}
                onClose={() => setDataSelected(undefined)}
                title={"Detail Transaksi"}
                size="lg"
                component={dataSelected !== undefined && <BookingEdit bookingId={dataSelected.id} onChange={handleEdit} />}
            />
            <CustomModal
                open={tabSelected === 1 && Boolean(dataSelected)}
                title="Edit Delivery"
                onClose={() => setDataSelected(undefined)}
                size="lg"
                component={dataSelected && <DeliveryEdit id={dataSelected?.id} onChange={handleEdit} />}
            />
            <Typography variant="h3">Piutang Jangka Panjang</Typography>
            <br />
            <Tabs value={tabSelected} onChange={(e, value) => setTabSelected(value)} aria-label="basic tabs example">
                <Tab value={0} label="Tiket" />
                <Tab value={1} label="pengiriman" />
            </Tabs>
            {tabSelected === 0 && (<BookingTableReceivableContract onEdit={access("EDIT") ? setDataSelected : undefined} countReload={countReload} />)}
            {tabSelected === 1 && (<DeliveryTableReceivableContract onEdit={access("EDIT") ? setDataSelected : undefined} countReload={countReload} />)}
        </Container>
    )
}