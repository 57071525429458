import { createSlice } from '@reduxjs/toolkit'
import { createBooking, createBookingPaid, deleteBooking, deleteBookingPaid, editBooking, editBookingCustomer, getBooking, getBookingDetail, getBookingDetailByDateAndSchedule, getBookingForItinerary, getBookingReceivable, getBookingReceivableContract } from './booking.api'
import { InterfaceBookingData, InterfaceBookingForItinerary, InterfaceBookingInput } from './booking.interface'
import alertError from '../../../shared/alert/alertError'

export interface BookingState {
    loading: boolean,
    bookingList: InterfaceBookingData[],
    bookingListTotal: number,
    bookingReceivableList: InterfaceBookingData[],
    bookingInput: InterfaceBookingInput,
    bookingItineraryList: InterfaceBookingForItinerary[]
}

const initialState: BookingState = {
    loading: false,
    bookingList: [],
    bookingListTotal: 0,
    bookingReceivableList: [],
    bookingInput: {},
    bookingItineraryList: []
}

export const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        setBookingInput: (state, action: { type: string, payload: InterfaceBookingInput }) => {
            state.bookingInput = action.payload
        }
    },
    extraReducers(builder) {

        builder.addCase(getBooking.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getBooking.fulfilled, (state, action) => {
            state.loading = false
            state.bookingList = action.payload.data.data
            state.bookingListTotal = action.payload.data.total_data
        })
        builder.addCase(getBooking.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getBookingReceivable.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getBookingReceivable.fulfilled, (state, action) => {
            state.loading = false
            state.bookingReceivableList = action.payload.data.data
        })
        builder.addCase(getBookingReceivable.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getBookingReceivableContract.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getBookingReceivableContract.fulfilled, (state, action) => {
            state.loading = false
            state.bookingReceivableList = action.payload.data.data
        })
        builder.addCase(getBookingReceivableContract.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getBookingDetail.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getBookingDetail.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getBookingDetail.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getBookingDetailByDateAndSchedule.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getBookingDetailByDateAndSchedule.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getBookingDetailByDateAndSchedule.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getBookingForItinerary.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getBookingForItinerary.fulfilled, (state, action) => {
            state.loading = false
            state.bookingItineraryList = action.payload.data.data
        })
        builder.addCase(getBookingForItinerary.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createBooking.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createBooking.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createBooking.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editBooking.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editBooking.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editBooking.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteBooking.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteBooking.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteBooking.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editBookingCustomer.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editBookingCustomer.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editBookingCustomer.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createBookingPaid.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createBookingPaid.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createBookingPaid.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteBookingPaid.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteBookingPaid.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteBookingPaid.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    }
})

export const { setBookingInput } = bookingSlice.actions

export default bookingSlice.reducer