import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccount } from "../../account/data/account.api";
import { RootState } from "../../../store";
import moneyParser from "../../../shared/moneyParser";
import { getItineraryReport } from "../data/itinerary.api";
import moment from "moment";

export default function ItineraryReport(props: {
    date_start: string,
    date_end: string
}) {

    const dispatch = useDispatch<any>();

    const { reportList } = useSelector((state: RootState) => state.itinerary)

    useEffect(() => {
        dispatch(getAccount())
        dispatch(getItineraryReport({ date_start: props.date_start, date_end: props.date_end }))
    }, [dispatch, props])

    return (
        <Card>
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nomor Itinerary</TableCell>
                            <TableCell>Tanggal Input</TableCell>
                            <TableCell>Kota Keberangkatan</TableCell>
                            <TableCell>Kota Tujuan</TableCell>
                            <TableCell>Tiket</TableCell>
                            <TableCell>Pengiriman</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Biaya</TableCell>
                            <TableCell>Komisi</TableCell>
                            <TableCell>Laba</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportList
                            .map((report, reportIndex) => {
                                const totalIncome = report.total_booking + report.total_delivery
                                const totalCost = report.total_cost + report.total_commision
                                const profit = totalIncome - totalCost
                                return (
                                    <TableRow>
                                        <TableCell>{report.id}</TableCell>
                                        <TableCell>{moment(report.ts_created).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                        <TableCell>{report.schedule.start_city.name}</TableCell>
                                        <TableCell>{report.schedule.finish_city.name}</TableCell>
                                        <TableCell align="right">{moneyParser(report.total_booking)}</TableCell>
                                        <TableCell align="right">{moneyParser(report.total_delivery)}</TableCell>
                                        <TableCell align="right">{moneyParser(totalIncome)}</TableCell>
                                        <TableCell align="right">{moneyParser(report.total_cost)}</TableCell>
                                        <TableCell align="right">{moneyParser(report.total_commision)}</TableCell>
                                        <TableCell align="right">{moneyParser(profit)}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }} colSpan={4}>Total</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(reportList.reduce((total, report) => total + report.total_booking, 0))}</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(reportList.reduce((total, report) => total + report.total_delivery, 0))}</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(reportList.reduce((total, report) => total + report.total_booking + report.total_delivery, 0))}</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(reportList.reduce((total, report) => total + report.total_cost, 0))}</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(reportList.reduce((total, report) => total + report.total_commision, 0))}</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(reportList.reduce((total, report) => total + ((report.total_booking + report.total_delivery) - (report.total_cost + report.total_commision)), 0))}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}