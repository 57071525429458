import { Box, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDeliverDetail } from "../data/delivery.api";
import { useParams } from "react-router-dom";
import { InterfaceDeliveryDetail } from "../data/delivery.interface";
import logo from "../../../assets/img/logo.png";
import moment from "moment";
import moneyParser from "../../../shared/moneyParser";

export default function DeliveryPrint() {

    const dispatch = useDispatch()
    const { id } = useParams()

    const [deliveryDetail, setDeliveryDetail] = useState<InterfaceDeliveryDetail | null>(null)

    useEffect(() => {
        if (id !== undefined) {
            dispatch<any>(getDeliverDetail(parseInt(id)))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        setDeliveryDetail(response.data.data)
                    }
                })
        }
    }, [dispatch, id])

    return (
        <Box>
            <table style={{ width: "100%", fontWeight: "bold" }}>
                <tbody>
                    {deliveryDetail?.package_list.map(pkg => (
                        <tr key={pkg.id}>
                            <td className="ticket">
                                <Divider />
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <img src={logo} alt="logo" height={48} />
                                        <Typography variant="body1" fontWeight="bold">0811303177</Typography>
                                        <Typography variant="body1" fontWeight="bold">0811353488</Typography>
                                    </Grid>
                                    <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                                        <Typography variant="h3" align="right">{deliveryDetail.id}</Typography>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ borderColor: "black", borderWidth: 2 }} />
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><Typography variant="body1" fontWeight="bold">Tanggal</Typography></td>
                                            <td><Typography variant="body1" fontWeight="bold">:</Typography></td>
                                            <td>
                                                <Typography variant="body1" fontWeight="bold">
                                                    {moment(deliveryDetail.ts_created).format('DD MMMM YYYY')}
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Typography variant="body1" fontWeight="bold">Pengirim</Typography></td>
                                            <td><Typography variant="body1" fontWeight="bold">:</Typography></td>
                                            <td>
                                                <Typography variant="body1" fontWeight="bold">
                                                    {deliveryDetail.sender_name} {deliveryDetail.sender_phone}
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Typography variant="body1" fontWeight="bold">Penerima</Typography></td>
                                            <td><Typography variant="body1" fontWeight="bold">:</Typography></td>
                                            <td>
                                                <Typography variant="body1" fontWeight="bold">
                                                    {deliveryDetail.receiver_name} {deliveryDetail.receiver_phone}
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <Typography variant="body1" fontWeight="bold">
                                                    {deliveryDetail.receiver_address}
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Typography variant="body1" fontWeight="bold">Jenis Barang</Typography></td>
                                            <td><Typography variant="body1" fontWeight="bold">:</Typography></td>
                                            <td>
                                                <Typography variant="body1" fontWeight="bold">
                                                    {deliveryDetail.package_type.name}
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Typography variant="body1" fontWeight="bold">Total Barang</Typography></td>
                                            <td><Typography variant="body1" fontWeight="bold">:</Typography></td>
                                            <td>
                                                <Typography variant="body1" fontWeight="bold">
                                                    {deliveryDetail.package_list.reduce((total, pkg) => total + pkg.weight, 0)} kg
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Typography variant="body1" fontWeight="bold">Harga</Typography></td>
                                            <td><Typography variant="body1" fontWeight="bold">:</Typography></td>
                                            <td>
                                                <Typography variant="body1" fontWeight="bold">
                                                    {moneyParser(deliveryDetail.total)} ({deliveryDetail.total_paid < deliveryDetail.total ? "Belum Lunas" : "Lunas"})
                                                </Typography>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Divider />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Box>
    )
}