import { Badge, Box, Button, Card, CardContent, CardHeader, IconButton, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from "@mui/material"
import moneyParser from "../../../shared/moneyParser"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { appendBookingItinerary, appendDeliveryItinerary, closeItinerary, getItineraryDetail } from "../data/itinerary.api"
import { RootState } from "../../../store"
import { useSetting } from "../../../hooks/useSetting"
import { ItineraryCloseFormCost } from "./itineraryCloseFormCost"
import Swal from "sweetalert2"
import alertSuccess from "../../../shared/alert/alertSuccess"
import useAccess from "../../../hooks/useAccess"
import ItineraryCloseFormAttachment from "./ItineraryCloseFormAttachment"
import { Add, Info } from "@mui/icons-material"
import CustomModal from "../../../shared/CustomModal"
import InputBooking from "../../booking/components/input/Input"
import InputDelivery from "../../delivery/components/input/Input"
import SeatBookingEditor from "../../seat/components/SeatBookingEditor"
import { InterfaceSeatData } from "../../seat/data/seat.interface"
import BookingEdit from "../../booking/components/BookingEdit"
import ItineraryCloseFormDelivery from "./ItineraryCloseFormDelivery"

export const ItineraryCloseForm = (props: { id?: number, onSubmit: () => void }) => {

    const dispatch = useDispatch<any>()
    const setting = useSetting()
    const access = useAccess()
    const accessBooking = useAccess("/booking")
    const accessDelivery = useAccess("/delivery")
    const accessTrasnactionTicket = useAccess("/transaction/ticket")
    const accessTrasnactionDelivery = useAccess("/transaction/delivery")

    const { itineraryDetail } = useSelector((state: RootState) => state.itinerary)

    const [modalAddBooking, setModalAddBooking] = useState(false)
    const [modalAddDelivery, setModalAddDelivery] = useState(false)
    const [modalBooking, setModalBooking] = useState(false)
    const [modalDelivery, setModalDelivery] = useState(false)
    const [seatSelected, setSeatSelected] = useState<InterfaceSeatData | null>(null)
    const [countReload, setCountReload] = useState(0)

    useEffect(() => {
        if (props.id !== undefined) {
            dispatch(getItineraryDetail(props.id))
        }
    }, [dispatch, props.id, countReload])

    const handleSubmitCost = () => {
        if (props.id !== undefined) {
            dispatch(getItineraryDetail(props.id))
        }
    }

    if (itineraryDetail === undefined) {
        return <>Loading</>
    }

    const bookingTotal = itineraryDetail.booking_list.reduce((totalBooking, booking) => {
        const totalTemp = booking.booking.customer_list.reduce((totalCustomer, customer) => totalCustomer + customer.price - (customer.price * customer.discount / 100), 0)
        return totalBooking + totalTemp
    }, 0)
    const bookingTotalUnpaid = itineraryDetail.booking_list.reduce((totalBooking, booking, i) => {
        let totalTemp = 0
        const isNotContract = booking.booking.paid_list.every(paid => !paid.is_contract)
        if (isNotContract) {
            const totalToPaid = booking.booking.customer_list.reduce((totalCustomer, customer) => totalCustomer + customer.price - (customer.price * customer.discount / 100), 0)
            const totalPaid = booking.booking.paid_list.filter(paid => paid.account_id !== setting.getValue("ACCOUNT_LOAN")).reduce((totalPaid, paid) => totalPaid + paid.value, 0)
            totalTemp = totalToPaid - totalPaid
        }
        return totalBooking + totalTemp
    }, 0)
    const bookingTotalUnpaidContract = itineraryDetail.booking_list.reduce((totalBooking, booking, i) => {
        let totalTemp = 0
        const isContract = booking.booking.paid_list.some(paid => paid.is_contract)
        if (isContract) {
            const totalToPaid = booking.booking.customer_list.reduce((totalCustomer, customer) => totalCustomer + customer.price - (customer.price * customer.discount / 100), 0)
            const totalPaid = booking.booking.paid_list.filter(paid => paid.account_id !== setting.getValue("ACCOUNT_LOAN")).reduce((totalPaid, paid) => totalPaid + paid.value, 0)
            totalTemp = totalToPaid - totalPaid
        }
        return totalBooking + totalTemp
    }, 0)
    const deliveryTotal = itineraryDetail.delivery_list.reduce((totalDelivery, delivery) => {
        const totalPriceBeforeDiscount = (delivery.delivery.price * delivery.delivery.package_list.reduce((t, p) => t + p.weight, 0)) + delivery.delivery.price_packing
        const discountPrice = totalPriceBeforeDiscount * delivery.delivery.discount / 100
        const totalTemp = totalPriceBeforeDiscount - discountPrice
        return totalDelivery + totalTemp
    }, 0)
    const deliveryTotalUnpaid = itineraryDetail.delivery_list.reduce((totalDelivery, delivery, i) => {
        let totalTemp = 0
        const isNotContract = delivery.delivery.paid_list.every(paid => !paid.is_contract)
        if (isNotContract) {
            const totalPriceBeforeDiscount = (delivery.delivery.price * delivery.delivery.package_list.reduce((t, p) => t + p.weight, 0)) + delivery.delivery.price_packing
            const discountPrice = totalPriceBeforeDiscount * delivery.delivery.discount / 100
            const totalToPaid = totalPriceBeforeDiscount - discountPrice
            const totalPaid = delivery.delivery.paid_list.filter(paid => paid.account_id !== setting.getValue("ACCOUNT_LOAN")).reduce((totalPaid, paid) => totalPaid + paid.value, 0)
            totalTemp = totalToPaid - totalPaid
        }
        return totalDelivery + totalTemp
    }, 0)
    const deliveryTotalUnpaidContract = itineraryDetail.delivery_list.reduce((totalDelivery, delivery, i) => {
        let totalTemp = 0
        const isContract = delivery.delivery.paid_list.some(paid => paid.is_contract)
        if (isContract) {
            const totalPriceBeforeDiscount = (delivery.delivery.price * delivery.delivery.package_list.reduce((t, p) => t + p.weight, 0)) + delivery.delivery.price_packing
            const discountPrice = totalPriceBeforeDiscount * delivery.delivery.discount / 100
            const totalToPaid = totalPriceBeforeDiscount - discountPrice
            const totalPaid = delivery.delivery.paid_list.filter(paid => paid.account_id !== setting.getValue("ACCOUNT_LOAN")).reduce((totalPaid, paid) => totalPaid + paid.value, 0)
            totalTemp = totalToPaid - totalPaid
        }
        return totalDelivery + totalTemp
    }, 0)

    const totalCost = itineraryDetail.cost_list.reduce((total, cost) => total + cost.value, 0)
    const omzet = (bookingTotal + deliveryTotal) - totalCost
    const commisionA = (omzet * 0.13) * 0.4
    const commisionB = (omzet * 0.13) * 0.3
    const commisionC = (omzet * 0.13) * 0.15
    const commisionD = (omzet * 0.13) * 0.15

    const handleClose = () => {
        Swal.fire({
            title: 'Tutup manifest',
            text: 'Apakah anda yakin ingin menutup manifest ini?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(closeItinerary({
                        id: itineraryDetail.id,
                        commision_list: [
                            {
                                itinerary_id: itineraryDetail.id,
                                employee_id: itineraryDetail.driver_primary_id,
                                account_source_id: setting.getValue("ACCOUNT_ITINERARY_COST_SOURCE_DEFAULT"),
                                account_id: setting.getValue("ACCOUNT_ITINERARY_COST_DESTINATION_COMMISION"),
                                value: commisionA
                            },
                            {
                                itinerary_id: itineraryDetail.id,
                                employee_id: itineraryDetail.driver_secondary_id,
                                account_source_id: setting.getValue("ACCOUNT_ITINERARY_COST_SOURCE_DEFAULT"),
                                account_id: setting.getValue("ACCOUNT_ITINERARY_COST_DESTINATION_COMMISION"),
                                value: commisionB
                            },
                            {
                                itinerary_id: itineraryDetail.id,
                                employee_id: itineraryDetail.helper_a_id,
                                account_source_id: setting.getValue("ACCOUNT_ITINERARY_COST_SOURCE_DEFAULT"),
                                account_id: setting.getValue("ACCOUNT_ITINERARY_COST_DESTINATION_COMMISION"),
                                value: commisionC
                            },
                            {
                                itinerary_id: itineraryDetail.id,
                                employee_id: itineraryDetail.helper_b_id,
                                account_source_id: setting.getValue("ACCOUNT_ITINERARY_COST_SOURCE_DEFAULT"),
                                account_id: setting.getValue("ACCOUNT_ITINERARY_COST_DESTINATION_COMMISION"),
                                value: commisionD
                            }
                        ]
                    })).unwrap()
                    await alertSuccess("Berhasil menutup manifest")
                    props.onSubmit()
                } catch (error) { }
            }
        })
    }

    const handleSubmitInputBooking = (newId: number) => {
        setModalAddBooking(false)
        dispatch(appendBookingItinerary({
            itinerary_id: itineraryDetail.id,
            booking_id: newId
        }))
            .unwrap()
            .then(() => {
                alertSuccess("Berhasil menambah booking")
                if (props.id !== undefined) {
                    dispatch(getItineraryDetail(props.id))
                }
            })
    }

    const handleSubmitInputDelivery = (newId: number) => {
        setModalAddDelivery(false)
        dispatch(appendDeliveryItinerary({
            itinerary_id: itineraryDetail.id,
            delivery_id: newId
        }))
            .unwrap()
            .then(() => {
                alertSuccess("Berhasil menambah pengiriman")
                if (props.id !== undefined) {
                    dispatch(getItineraryDetail(props.id))
                }
            })
    }

    const handleChangeData = (close?: boolean) => {
        setCountReload(countReload + 1)
        if (close) {
            setSeatSelected(null)
        }
    }

    return (
        <Box>
            <CustomModal
                open={accessBooking("CREATE") && modalAddBooking}
                onClose={() => setModalAddBooking(false)}
                title={"Input Booking"}
                size="lg"
                component={<InputBooking startDate={itineraryDetail.start_date} scheduleId={itineraryDetail.schedule_id} isAllowSubmit={true} onSubmit={handleSubmitInputBooking} />}
            />
            <CustomModal
                open={accessDelivery("CREATE") && modalAddDelivery}
                onClose={() => setModalAddDelivery(false)}
                title={"Input Delivery"}
                size="lg"
                component={<InputDelivery scheduleId={itineraryDetail.schedule_id} onSubmit={handleSubmitInputDelivery} />}
            />
            <CustomModal
                open={accessTrasnactionTicket("EDIT") && modalBooking}
                onClose={() => setModalBooking(false)}
                title={"Pelunasan Booking"}
                component={<SeatBookingEditor scheduleId={itineraryDetail.schedule_id} startDate={itineraryDetail.start_date} countReload={countReload} onClick={seat => setSeatSelected(seat)} />}
            />
            <CustomModal
                open={accessTrasnactionDelivery("EDIT") && modalDelivery}
                onClose={() => setModalDelivery(false)}
                title={"Pelunasan Pengiriman"}
                component={<ItineraryCloseFormDelivery deliveryList={itineraryDetail.delivery_list} onChange={() => setCountReload(countReload + 1)} />}
            />
            <CustomModal
                open={seatSelected?.customer_list !== undefined && seatSelected.customer_list.length > 0}
                onClose={() => setSeatSelected(null)}
                title={"Detail Seat"}
                size="md"
                component={seatSelected?.customer_list !== undefined && seatSelected.customer_list?.length > 0 && <BookingEdit bookingId={seatSelected.customer_list[0].booking.id} onChange={handleChangeData} />}
            />
            <Card>
                <CardHeader title="Pendapatan" />
                <CardContent>
                    <Typography variant="h6">
                        Tiket
                        <IconButton disabled={!accessBooking("CREATE")} onClick={() => setModalAddBooking(true)}>
                            <Add />
                        </IconButton>
                        <Tooltip title="Daftar Transaksi Tiket">
                            <Badge color="primary" variant={bookingTotalUnpaid > 0 ? "dot" : undefined}>
                                <IconButton disabled={!accessTrasnactionTicket("EDIT")} onClick={() => setModalBooking(true)}>
                                    <Info />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                    </Typography>
                    <Table sx={{ marginBottom: 3 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Pendapatan tiket </TableCell>
                                <TableCell align="right">{moneyParser(bookingTotal)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Piutang jangka pendek</TableCell>
                                <TableCell align="right">{moneyParser(bookingTotalUnpaid)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Piutang jangka panjang</TableCell>
                                <TableCell align="right">{moneyParser(bookingTotalUnpaidContract)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Typography variant="h6">
                        Pengiriman
                        <IconButton disabled={!accessDelivery("CREATE")} onClick={() => setModalAddDelivery(true)}>
                            <Add />
                        </IconButton>
                        <Tooltip title="Daftar Transaksi Pengiriman">
                            <Badge color="primary" variant={deliveryTotalUnpaid > 0 ? "dot" : undefined}>
                                <IconButton disabled={!accessTrasnactionDelivery("EDIT")} onClick={() => setModalDelivery(true)}>
                                    <Info />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                    </Typography>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Pendapatan pengiriman </TableCell>
                                <TableCell align="right">{moneyParser(deliveryTotal)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Piutang jangka pendek</TableCell>
                                <TableCell align="right">{moneyParser(deliveryTotalUnpaid)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Piutang jangka panjang</TableCell>
                                <TableCell align="right">{moneyParser(deliveryTotalUnpaidContract)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <Card sx={{ marginTop: 1 }}>
                <CardHeader title="Biaya" />
                <CardContent>
                    <ItineraryCloseFormCost itinerary_id={itineraryDetail.id} cost_list={itineraryDetail.cost_list} onSubmit={handleSubmitCost} />
                </CardContent>
            </Card>
            <Card sx={{ marginTop: 1 }}>
                <CardHeader title="Lampiran" />
                <CardContent>
                    <ItineraryCloseFormAttachment itineraryId={itineraryDetail.id} />
                </CardContent>
            </Card>
            {access("VIEW_SENSITIVE") && (
                <Card sx={{ marginTop: 1 }}>
                    <CardHeader title="Omset" />
                    <CardContent>
                        <Card>
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Total Pendapatan</TableCell>
                                            <TableCell align="right">{moneyParser(bookingTotal + deliveryTotal)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Total Biaya</TableCell>
                                            <TableCell align="right">{moneyParser(totalCost)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Omset Bruto</TableCell>
                                            <TableCell align="right">{moneyParser(omzet)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                        <Card sx={{ marginTop: 1 }}>
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>13% Omset Bruto</TableCell>
                                            <TableCell align="right">{moneyParser(omzet * 0.13)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>40% Komisi Sopir Utama</TableCell>
                                            <TableCell align="right">{moneyParser(commisionA)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>30% Komisi Sopir Cadangan</TableCell>
                                            <TableCell align="right">{moneyParser(commisionB)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>15% Komisi Kernet 1</TableCell>
                                            <TableCell align="right">{moneyParser(commisionC)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>15% Komisi Kernet 2</TableCell>
                                            <TableCell align="right">{moneyParser(commisionD)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                        <Card sx={{ marginTop: 1 }}>
                            <CardContent>
                                <Typography variant="h6" align="left">Omset Netto</Typography>
                                <Typography variant="h3" align="right">{moneyParser(omzet * 0.87)}</Typography>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            )}
            <Button fullWidth sx={{ marginTop: 1 }} disabled={bookingTotalUnpaid > 0 || deliveryTotalUnpaid > 0} onClick={handleClose}>Tutup manifest</Button>
        </Box>
    )
}