import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getEmployee } from "../data/employee.api";
import { InterfaceEmployeeData, TypeEmployeeType } from "../data/employee.interface";

export default function EmployeeSelect(props: {
    label?: string,
    value: number | null | undefined,
    onChange: (value: number | null, valueDetail: InterfaceEmployeeData | null) => void,
    required?: boolean,
    disabled?: boolean,
    type?: TypeEmployeeType[],
    readOnly?: boolean,
}) {

    const dispatch = useDispatch<any>()
    const { loading, employeeList } = useSelector((state: RootState) => state.employee)

    useEffect(() => {
        dispatch(getEmployee())
    }, [dispatch])

    return (
        <Box>
            {loading
                ? (
                    <TextField fullWidth label={props.label || "Pegawai"} />
                )
                : (
                    <Autocomplete
                        fullWidth
                        disabled={props.disabled}
                        options={employeeList.filter(employee => !props.type || employee.type === "ALL" || props.type.includes(employee.type))}
                        getOptionLabel={options => options.name}
                        value={employeeList.filter(employee => employee.id === props.value)[0]}
                        onChange={(_, value) => props.onChange(value?.id || null, value || null)}
                        readOnly={props.readOnly}
                        renderInput={(params) => <TextField {...params} label={props.label || "Pegawai"} required={props.required} />}
                    />
                )
            }
        </Box>
    )
}