import ItineraryPrint from "./features/itinerary/components/ItineraryPrint"
import SchedulePrint from "./features/booking/components/SchedulePrint"
import BookingPrint from "./features/booking/components/BookingPrint"
import DeliveryPrint from "./features/delivery/components/DeliveryPrint"
import BookingPrintAll from "./features/booking/components/BookingPrintAll"

const routeListPrint = [
    {
        path: "/itinerary/print/:id",
        component: <ItineraryPrint />
    },
    {
        path: "/schedule/print/:id/:startDate",
        component: <SchedulePrint />
    },
    {
        path: "/booking/print/:id",
        component: <BookingPrint />
    },
    {
        path: "/booking/printall/:id/:startDate",
        component: <BookingPrintAll />
    },
    {
        path: "/delivery/print/:id",
        component: <DeliveryPrint />
    }
]

export default routeListPrint